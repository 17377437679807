/**
 * The BreadcrumbsData contains all the data needed to hydrate a Breadcrumbs component
 *
 * @typedef {Object}      BreadcrumbsData
 *
 * @property {Bool}                     structuredData        - True if the breadcrumbs use structured data attributes
 * @property {Bool}                     unclickable          - If true will not display hover states and remark last item.
 * @property {BreadcrumbsItemData}      items                 - The items of the breadcrumb
 * @property {String}                   extraClasses          - Extra classes if needed
 */

/**
 * The BreadcrumbsItemData contains all the data needed to hydrate a BreadcrumbsItem component
 *
 * @typedef {Object}      BreadcrumbsItemData
 *
 * @property {Bool}                     structuredData        - True if the breadcrumbs use structured data attributes
 * @property {String}                   text                  - The content of the item
 * @property {Bool}                     last                  - True if this is the last item
 */

export const defaultBreadcrumbsData = {
  structuredData: false,
  unclickable: true,
  items: [],
  extraClasses: ''
}

export const defaultBreadcrumbsItemData = {
  last: false,
  text: ''
}

export const BreadcrumbsTemplate = (model) => {
  model = { ...defaultBreadcrumbsData, ...model }

  if (model.items.length > 0) {
    model.items.map(item => (item.last = false))
    model.items[model.items.length - 1].last = true
  }

  return `
<ol class="c-breadcrumbs
  ${model.unclickable ? 'c-breadcrumbs--unclickable' : ''}
  ${model.extraClasses}"
    ${model.structuredData ? 'itemscope itemtype="http://schema.org/BreadcrumbList"' : ''}>

  ${model.items.map((item, index) => BreadcrumbsItemTemplate(item, model, index)).join('').trim()}

</ol>
`
}

export const BreadcrumbsItemTemplate = (item, model, index) => {
  const href = item.href ? item.href : '#'
  return `
  <li class="c-breadcrumbs__item" data-divider="${model.unclickable ? ',' : '›'}"
    ${model.structuredData ? ' itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"' : ''}>
    <${item.unclickable ? 'div' : `a href="${href}"`} class="c-breadcrumbs__link ${item.unclickable ? 'c-breadcrumbs__link--unclickable' : ''} "${model.structuredData ? 'itemprop="item"' : ''}>
      <span class="c-breadcrumbs__text" ${model.structuredData ? 'itemprop="name"' : ''}>
        ${item.text}
      </span>
    </${item.unclickable ? 'div' : 'a'}>
    <meta itemprop="position" content="${index}">
  </li>
  `
}
