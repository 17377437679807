/**
 * List of events used by tabs component
 *
 */
export const tabsEvents = {
  CLICKED: 'clicked',
  LOADED: 'loaded',
  TAB_CHANGED: 'tabChanged',
  PROP_CHANGED: 'propChanged'
}
