import { registerWidget } from '../../../js/core/widget/widget-directory'
import { fetchJsonData } from '../../../js/helpers/json-fetch'
import { bindClickEventToResults } from '../../../js/helpers/event-binder'
import { getUrlFromString } from '../../../js/document/url'
import { processPackageItem } from '../../../js/data/data-processor'
import { AccoListerContentTemplate } from './w-acco-lister__content.template'
import Component from '../../../js/core/component/component'
import Img from '../../components/img/main'
import registeredEvents from '../../../js/helpers/registered-events'
import { accoListerEvents } from './event-types'
import { getACMPriceLabel } from '../../components/price/c-price.template'
const EventEmitter = require('eventemitter3')

const ACM_MODAL_ID_SUFFIX = '__acm-modal'

const widgetApi = 'w-acco-lister'
const widgetQueries = {
  urlAttr: `data-${widgetApi}__url`,
  urlAcmAttr: `data-${widgetApi}__acm-base-url`,
  priceLabelTextAttr: `data-${widgetApi}__price-label-text`,
  sliderAttr: `data-${widgetApi}-slider`,
  variantAttr: `data-${widgetApi}__variant`,
  showCtaButtonAttr: `data-${widgetApi}__show-cta-button`,
  ctaButtonTextAttr: `data-${widgetApi}__cta-button-text`,
  extraParams: 'input[type="hidden"]',
  resultsElement: '.w-acco-lister__results',
  trackAttr: 'data-track'
}

export default class AccoLister {
  /**
   * Creates a new AccoLister
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element.
   * @param {options} [options={}] - Options object
   *
   */
  constructor (element, options = {}) {
    this.element = element
    this.acmModalId = `${widgetApi}-${this.element.id}${ACM_MODAL_ID_SUFFIX}`
    this.resultsElement = this.element.querySelector(widgetQueries.resultsElement)
    this.packageItems = []
    this.events = new EventEmitter()
    this.track = this.element.hasAttribute(widgetQueries.trackAttr) ? this.element.attributes[widgetQueries.trackAttr].value : null

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.element.attributes[widgetQueries.trackAttr].value }
    })

    this.options = {
      ...{
        url: this.element.getAttribute(widgetQueries.urlAttr),
        acmBaseUrl: this.element.getAttribute(widgetQueries.urlAcmAttr),
        priceLabelText: this.element.getAttribute(widgetQueries.priceLabelTextAttr),
        variant: this.element.getAttribute(widgetQueries.variantAttr) || 'default',
        showCtaButton: this.element.getAttribute(widgetQueries.showCtaButtonAttr) || false,
        ctaButtonText: this.element.getAttribute(widgetQueries.ctaButtonTextAttr) || '',
        urlParams: this.getExtraParameters()
      },
      ...options
    }
    if (!options.items) {
      this.fetchAndUpdate()
        .catch(e => console.warn(e))
    } else {
      this.resultsElement.innerHTML = this.getAccoListerTipsTemplate(options)
      bindClickEventToResults(this.packageItems, this._getLinksForEachResult(), this.onClickItem, this.events)
    }

    // Expose component public API
    this.element[widgetApi] = {
      element: this.element,
      options: this.options
    }
  }

  // Return an acco-lister with the packages in 'options.items'
  getAccoListerTipsTemplate (options = []) {
    if (!options.items || !options.items.length > 0) return null
    this.events.emit(accoListerEvents.ACCO_LISTER_PRINTED, options.items)
    options.items = options.items.map(item => {
      const modalId = options.acmModalId ? options.acmModalId : this.acmModalId
      return processPackageItem(item,
        {
          acmModalId: modalId,
          acmHideInfoIcon: !!item.price.mandatoryExtraCostsText,
          priceLegend: this.options.priceLabelText,
          priceLabel: item.price.mandatoryExtraCostsText && item.price.acmInformation
            ? getACMPriceLabel({ ...item, text: item.price.mandatoryExtraCostsText, staticText: item.price.staticText, acmUrl: item.price.acmInformation.acmUrl, modalId })
            : item.price.staticText,
          priceLabelGrayed: true,
          acmBaseUrl: this.options.acmBaseUrl,
          originList: options.originList ? options.originList : 'acco-lister'
        })
    })

    this.packageItems = options.items
    options.showCtaButton = this.options.showCtaButton
    options.ctaButtonText = this.options.ctaButtonText
    const accoListerContent = AccoListerContentTemplate(options)
    return accoListerContent
  }

  getExtraParameters () {
    const extraParamsElements = this.element.querySelectorAll(widgetQueries.extraParams)
    return extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          obj[el.name] = el.value
          return obj
        }, {})
      : undefined
  }

  async fetchAndUpdate () {
    const absoluteUrl = getUrlFromString(this.options.url, this.options.urlParams)
    const results = await fetchJsonData(absoluteUrl, { fullReferrerOnCrossOrigin: true })

    if (results) {
      this.element.classList.remove('is-loading')
      this.resultsElement.innerHTML = this.getAccoListerTipsTemplate({
        id: this.element.id,
        variant: this.options.variant,
        items: results.results,
        track: this.track ? this.track : null
      })
      bindClickEventToResults(this.packageItems, this._getLinksForEachResult(), this.onClickItem, this.events)
      Component.initDocumentComponentsFromAPI(this.element)
      Component.initComponentActionElements(this.element)
      Img.createInstancesOnDocument(this.element)
    }
  }

  _getLinksForEachResult () {
    const resultElements = Array.from(this.resultsElement.querySelectorAll('.c-package-item'))
    return resultElements.map(result => Array.from(result.querySelectorAll('.c-package-item__link')))
  }

  onClickItem (ev, result, events) {
    events.emit(accoListerEvents.ACCO_LISTER_ITEM_CLICKED, { data: { ...result }, event: ev })
  }
}

registerWidget(AccoLister, widgetApi)
