
/**
 * ComponentPropertyConfig
 *
 * Most components can be customized with different parameters when they
 * are created. These creation parameters are called props.
 *
 * This lets you make a single component that is used in many different
 * places in your app, with slightly different properties in each place.
 *
 * @global
 * @typedef {(String|Boolean|Number|Array)} ComponentPropertyValue - Describes a value for a property
 *
 * @global
 * @typedef {Object}    ComponentPropertyConfig
 * @typedef {String}    ComponentPropertyConfig.name - Name of the property
 * @typedef {String}    ComponentPropertyConfig.type - Type definition, String, Boolean, Number, Array, Collection
 * @typedef {String}   [ComponentPropertyConfig.attr] - Related attribute, classNames are allowed with leading .dot
 * @typedef {Boolean}  [ComponentPropertyConfig.required] - Required property
 * @typedef {ComponentPropertyValue} [ComponentPropertyConfig.defaultValue] - Default value for unset properties
 * @typedef {String[]} [ComponentPropertyConfig.allowedValues] - Allowed values for string properties
 * @typedef {Number}   [ComponentPropertyConfig.min] - Min number for number properties
 * @typedef {Number}   [ComponentPropertyConfig.max] - Max number for number properties
 *
 * @global
 * @typedef {ComponentPropertyConfig[]} ComponentProperties - A collection of ComponentPropertyConfig
 */

export default class ComponentProperty {
  /**
   * Creates a new componentProperty instance
   *
   * @constructor
   * @param {ComponentPropertyConfig} config - The component property configuration
   *
   */
  constructor (config) {
    this.config = Object.assign({
      name: '',
      type: 'boolean',
      required: false,
      allowedValues: []
    }, config)
    this.name = this.config.name
    this.className = this.config.attr ? this.config.attr.startsWith('.') ? this.config.attr.substring(1) : undefined : undefined
  }

  /**
   * Gets the current value from an HTML element attributes
   *
   * @param {HTMLElement} el
   * @returns {ComponentPropertyValue} given value
   *
   */
  getValueFromElementAttributes (el) {
    if (!this.config.attr) {
      return undefined
    }
    switch (this.config.type) {
      case 'string':
        return this._getStringValueFromElementAttributes(el)
      case 'number':
        return this._getNumberValueFromElementAttributes(el)
      case 'boolean':
        return this._getBooleanValueFromElementAttributes(el)
      case 'array':
        return this._getArrayValueFromElementAttributes(el)
    }
  }

  /**
   * Sets a given value to an HTML element attributes
   *
   * @param {HTMLElement} el
   * @param {ComponentPropertyValue} value
   *
   */
  setValueToElementAttributes (el, value) {
    if (!this.config.attr) return

    switch (this.config.type) {
      case 'string':
        if (this.className) {
          el.classList.remove(...this.config.allowedValues.map((allowedValue) => `${this.className}${allowedValue}`))
          if (value) el.classList.add(`${this.className}${value}`)
        } else {
          el.setAttribute(this.config.attr, value)
        }
        break
      case 'number':
        el.setAttribute(this.config.attr, value)
        break
      case 'boolean':
        this.className
          ? el.classList[value ? 'add' : 'remove'](this.className)
          : el[value ? 'setAttribute' : 'removeAttribute'](this.config.attr, '')
        break
      case 'array':
        el.setAttribute(this.config.attr, value)
        break
    }
  }

  /**
   * Gets the current value from an HTML element attributes
   *
   * @param {ComponentPropertyValue} value
   *
   */
  isAllowedValue (value) {
    switch (this.config.type) {
      case 'string':
        return this._isExpectedType(value) && this._isAllowedString(value)
      case 'number':
        return this._isExpectedType(value) && this._isAllowedNumber(value)
      case 'boolean':
        return this._isExpectedType(value)
      default:
        return true
    }
  }

  _isExpectedType (value) {
    switch (this.config.type) {
      case 'string':
        return (typeof value === 'string')
      case 'number':
        return (typeof value === 'number')
      case 'boolean':
        return (typeof value === 'boolean')
      case 'array':
      case 'collection':
        return Array.isArray(value)
      default:
        return true
    }
  }

  _isAllowedString (value) {
    return (this.config.allowedValues && this.config.allowedValues.length) ? this.config.allowedValues.includes(value) : true
  }

  _isAllowedNumber (value) {
    if (this.config.min !== undefined && value <= this.config.min) return false
    if (this.config.max !== undefined && value >= this.config.max) return false
    return true
  }

  _getStringValueFromElementAttributes (el) {
    if (this.className) {
      const valueCandidates = this.config.allowedValues.filter(allowedValue => el.classList.contains(`${this.className}${allowedValue}`))
      return valueCandidates[0] || Object.prototype.hasOwnProperty.call(this.config, 'defaultValue') ? this.config.defaultValue : undefined
    } else {
      const value = el.getAttribute(this.config.attr)
      return this.isAllowedValue(value) ? value : this.config.defaultValue
    }
  }

  _getNumberValueFromElementAttributes (el) {
    if (!el.hasAttribute(this.config.attr)) {
      return this.config.defaultValue
    }
    const value = parseInt(el.getAttribute(this.config.attr))
    return this.isAllowedValue(value) ? value : this.config.defaultValue
  }

  _getBooleanValueFromElementAttributes (el) {
    return this.className
      ? el.classList.contains(this.className)
      : el.hasAttribute(this.config.attr)
  }

  _getArrayValueFromElementAttributes (el) {
    if (!el.hasAttribute(this.config.attr)) {
      return this.config.defaultValue
    }
    const value = el.getAttribute(this.config.attr).split(',')
    return this.isAllowedValue(value) ? value : this.config.defaultValue
  }
}
