/**
 * The TableItemTemplate contains all the data needed to hydrate a TableItem component
 *
 * @typedef {Object}      TableItemTemplate
 *
 * @property {String}     extraClasses      - Extra classes if needed
 * @property {String}     innerHTML         - The content inside the table item
 */

export const defaultTableItemData = {
  extraClasses: ''
}

export const TableItemTemplate = (d) => `
  <div 
  class="c-table__item 
  ${d.extraClasses ? `${d.extraClasses}` : ''}">
    ${d.innerHTML ? `${d.innerHTML}` : ''}
  </div>
`
