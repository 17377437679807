/**
 * List of events used by search widget
 *
 */
export const search = {
  SEARCH_FILTERS_APPLIED: 'Search.FiltersApplied',
  SEARCH_RESULTS: 'Search.Results',
  SEARCH_ERROR: 'Search.Error',
  SEARCH_REMOVE_SINGLE_CHIP_FILTER: 'Search.RemoveSingleChipFilter',
  SEARCH_RESULT_CLICK: 'Search.ResultClick',
  SEARCH_MODIFIER_CHANGE: 'Search.ModifierChanged',
  SEARCH_SORT_CHANGE: 'Search.SortingChanged',
  SEARCH_PAGINATION_CHANGE: 'Search.PaginationChanged',
  SEARCH_MAP_OPENED: 'Search.MapOpened'
}
