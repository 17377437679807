/**
 * List of events used by collapse component
 *
 */
export const collapseEvents = {
  OPEN: 'open',
  OPENED: 'opened',
  CLOSE: 'close',
  CLOSED: 'closed'
}
