/**
 * The TableTemplate contains all the data needed to hydrate a Table component
 *
 * @typedef {Object}      TableTemplate
 *
 * @property {String}     variant           - The variant that will be used
 * @property {String}     extraClasses      - Extra classes if needed
 * @property {String}     innerHTML         - The content that should be shown inside the table
 */

import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-table__tokens.json')['c-table']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/table/data/c-table__tokens.json`)['c-table'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultTableData = {
  variant: 'default',
  extraClasses: ''
}

export const TableTemplate = (d) => `
  <div
  class="c-table
  ${d.variant ? getTokenClass('variant', d.variant, tokens) : ''} 
  ${d.extraClasses ? `${d.extraClasses}` : ''} ">
    ${d.innerHTML ? `${d.innerHTML}` : ''}
  </div>
`
